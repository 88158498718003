import { connect } from 'react-redux';
import VerificationSchedule from '../../Components/VerificationSchedule';
import withIframeHistory from '../withIframeHistory';
import { mapToIframeLanguage } from '../../translations/i18n';
import { VERIFICATION_SCHEDULE } from '../../constants/screens';
import Config from '../../Config';

const mapStateToProps = (state, ownProps) => ({
  currentLanguage: mapToIframeLanguage(state.auth.currentLanguage),
  currentUrl: state.iframeHistory[VERIFICATION_SCHEDULE],
  ...ownProps,
});

export default connect(mapStateToProps, {})(withIframeHistory(VerificationSchedule, {
  name: VERIFICATION_SCHEDULE,
  defaultUrl: Config.reactApp.VERIFICATION_SCHEDULE_URL
}));
