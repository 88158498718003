import React from 'react';
import { Icon } from 'antd';
import * as screens from '../../constants/screens';

/**
 *
 * @param {*} ability function get from AbilityContext
 * @param {*} t translation function
 */
export const buildManagerMenuItems = (ability, t) => {
  const menuItems = [];

  if (!ability) {
    return [];
  }

  if (ability.can('view', screens.PERFORM_MANAGER_VERIFICATION)) {
    menuItems.push({
      container: 'Perform Manager Verification',
      icon: undefined,
      title: t('sideMenu:performManagerVerification'),
      path: '/perform-manager-verification',
    });
  }

  if (ability.can('view', screens.MANAGER_CONTROL_CHECKLIST)) {
    menuItems.push({
      container: 'Manager Control Checklist',
      icon: undefined,
      title: t('sideMenu:managerControlChecklist'),
      path: '/manager-control-checklist',
    });
  }

  if (ability.can('view', screens.MANAGER_VERIFICATION_LIST)) {
    menuItems.push({
      container: 'Manager Verification List',
      icon: undefined,
      title: t('sideMenu:managerVerificationList'),
      path: '/manager-verification-list',
    });
  }

  return menuItems;
};

/**
 *
 * @param {*} ability function get from AbilityContext
 * @param {*} t translation function
 */
export const buildSupervisorMenuItems = (ability, t) => {
  const menuItems = [];

  if (!ability) {
    return [];
  }

  if (ability.can('view', screens.PERFORM_SUPERVISOR_VERIFICATION)) {
    menuItems.push({
      container: 'Perform Supervisor Verification',
      icon: undefined,
      title: t('sideMenu:performSupervisorVerification'),
      path: '/supervisor-perform-verification',
    });
  }

  if (ability.can('view', screens.PERFORM_SUPERVISOR_TASK_BASED_VERIFICATION)) {
    menuItems.push({
      container: 'Perform Supervisor Task Based Verification',
      icon: undefined,
      title: t('sideMenu:performSupervisorTaskBasedVerification'),
      path: '/supervisor-perform-task-based-verification',
    });
  }

  if (ability.can('view', screens.SUPERVISOR_VERIFICATION_LIST)) {
    menuItems.push({
      container: 'Supervisor Verification List',
      icon: undefined,
      title: t('sideMenu:supervisorVerificationList'),
      path: '/supervisor-verification-list',
    });
  }

  if (ability.can('view', screens.SUPERVISOR_OPERATOR_RISK_TBC)) {
    const menu = {
      key: 'Supervisor-Operator-Risk-Task-Based-Checklist',
      title: t('sideMenu:supervisorOperatorRiskTaskBasedChecklist'),
      icon: <Icon type="appstore" />,
      children: [
        {
          container: 'Supervisor Operator Risk Task Based Checklist',
          icon: undefined,
          title: t('sideMenu:supervisorOperatorRiskTaskBasedChecklist'),
          path: '/crc-list',
        },
      ],
    };

    if (ability.can('view', screens.CREATE_SUPERVISOR_OPERATOR_RISK_TBC)) {
      menu.children.push({
        container: 'Create Supervisor Operator Task Based Checklist',
        icon: undefined,
        title: t('sideMenu:createSupervisorOperatorTaskBasedChecklist'),
        path: '/node/add/crc',
      });
    }

    menuItems.push(menu);
  }

  return menuItems;
};

/**
 *
 * @param {*} ability function get from AbilityContext
 * @param {*} t translation function
 */
export const buildOperatorMenuItems = (ability, t) => {
  const menuItems = [];

  if (!ability) {
    return [];
  }

  if (ability.can('view', screens.PERFORM_OPERATOR_VERIFICATION)) {
    menuItems.push({
      container: 'Perform Operator Verification',
      icon: undefined,
      title: t('sideMenu:performOperatorVerification'),
      path: '/operator-perform-verification',
    });
  }

  if (ability.can('view', screens.PERFORM_OPERATOR_TASK_BASED_VERIFICATION)) {
    menuItems.push({
      container: 'Perform Operator Task Based Verification',
      icon: undefined,
      title: t('sideMenu:performOperatorTaskBasedVerification'),
      path: '/operator-perform-task-based-verification',
    });
  }

  return menuItems;
};

/**
 *
 * @param {*} ability function get from AbilityContext
 * @param {*} t translation function
 */
export const buildDailyNotificationMenuItems = (ability, t) => {
  const menuItems = [];

  if (!ability) {
    return [];
  }

  if (ability.can('view', screens.CREATE_DAILY_NOTIFICATION)) {
    menuItems.push({
      container: 'Create Daily Notification',
      icon: undefined,
      title: t('sideMenu:createDailyNotification'),
      path: '/create-daily-notification',
    });
  }

  if (ability.can('view', screens.DAILY_NOTIFICATION_LIST)) {
    menuItems.push({
      container: 'Daily Notification List',
      icon: undefined,
      title: t('sideMenu:dailyNotificationList'),
      path: '/daily-notification-list',
    });
  }

  return menuItems;
};

/**
 *
 * @param {*} ability function get from AbilityContext
 * @param {*} t translation function
 */
export const buildVerificationScheduleMenuItems = (ability, t) => {
  const menuItems = [];

  if (!ability) {
    return [];
  }

  if (ability.can('view', screens.VERIFICATION_SCHEDULE)) {
    menuItems.push({
      container: 'View verification schedule',
      icon: undefined,
      title: t('sideMenu:verificationSchedule'),
      path: '/verification-schedule',
    });
  }

  if (ability.can('view', screens.VERIFICATION_SCHEDULE_MANAGEMENT)) {
    menuItems.push({
      container: 'Verification Schedule Management',
      icon: undefined,
      title: t('sideMenu:verificationScheduleManagement'),
      path: '/verification-schedule-management',
    });
  }

  return menuItems;
};

/**
 *
 * @param {*} ability function get from AbilityContext
 * @param {*} t translation function
 */
export const buildReportMenuItems = (ability, t) => {
  const menuItems = [];

  if (!ability) {
    return [];
  }

  if (ability.can('view', screens.REPORT_SUBMISSION_TOTALS)) {
    menuItems.push({
      container: 'ReportSubmissionTotals',
      icon: undefined,
      title: t('sideMenu:reportSubmissionTotals'),
      path: '/mrm-statistics/ccps/statistics/submission-totals',
    });
  }

  if (ability.can('view', screens.REPORT_STANDARD_REPORT)) {
    menuItems.push({
      container: 'ReportStandardReport',
      icon: undefined,
      title: t('sideMenu:reportStandardReport'),
      path: '/mat_risk_standard_report',
    });
  }

  if (ability.can('view', screens.REPORT_VERIFICATION_SCHEDULER_REPORTS)) {
    menuItems.push({
      container: 'ReportVerificationSchedulerReports',
      icon: undefined,
      title: t('sideMenu:reportVerificationSchedulerReports'),
      path: '/verification_scheduler_reports',
    });
  }

  if (ability.can('view', screens.REPORT_QUESTION_NON_COMPLIANCE_REPORT)) {
    menuItems.push({
      container: 'ReportQuestionNonComplianceReport',
      icon: undefined,
      title: t('sideMenu:reportQuestionNonComplianceReport'),
      path: '/question-non-compliance-report',
    });
  }

  if (ability.can('view', screens.REPORT_ACTION_PLAN_REPORT)) {
    menuItems.push({
      container: 'ReportActionPlanReport',
      icon: undefined,
      title: t('sideMenu:reportActionPlanReport'),
      path: '/action_plan_report',
    });
  }

  if (ability.can('view', screens.REPORT_AP_EFFECT_REPORT)) {
    menuItems.push({
      container: 'ReportApEffectReport',
      icon: undefined,
      title: t('sideMenu:reportApEffectReport'),
      path: '/ap-effect-report',
    });
  }

  if (ability.can('view', screens.REPORT_VERIFICATION_PARTICIPATION_SUMMARY)) {
    menuItems.push({
      container: 'ReportVerificationParticipationSummary',
      icon: undefined,
      title: t('sideMenu:reportVerificationParticipationSummary'),
      path: '/verification-participation-summary',
    });
  }

  if (ability.can('view', screens.REPORT_ACTION_PLAN_STATUS)) {
    menuItems.push({
      container: 'ReportActionPlanStatus',
      icon: undefined,
      title: t('sideMenu:reportActionPlanStatus'),
      path: '/action-plan-status',
    });
  }

  return menuItems;
};
