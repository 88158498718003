import { connect } from 'react-redux';
import ManagerVerificationList from '../../Components/ManagerVerificationList';
import withIframeHistory from '../withIframeHistory';
import { MANAGER_VERIFICATION_LIST } from '../../constants/screens';
import { mapToIframeLanguage } from '../../translations/i18n';
import Config from '../../Config';

const mapStateToProps = (state, ownProps) => ({
  currentLanguage: mapToIframeLanguage(state.auth.currentLanguage),
  currentUrl: state.iframeHistory[MANAGER_VERIFICATION_LIST],
  ...ownProps
});

export default connect(mapStateToProps, {})(withIframeHistory(ManagerVerificationList, {
  name: MANAGER_VERIFICATION_LIST,
  defaultUrl: Config.reactApp.MANAGER_VERIFICATION_LIST_URL
}));
