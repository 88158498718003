import { connect } from 'react-redux';
import SupervisorVerificationList from '../../Components/SupervisorVerificationList';
import withIframeHistory from '../withIframeHistory';
import { mapToIframeLanguage } from '../../translations/i18n';
import { SUPERVISOR_VERIFICATION_LIST } from '../../constants/screens';
import Config from '../../Config';

const mapStateToProps = (state, ownProps) => ({
  currentLanguage: mapToIframeLanguage(state.auth.currentLanguage),
  currentUrl: state.iframeHistory[SUPERVISOR_VERIFICATION_LIST],
  ...ownProps
});

export default connect(mapStateToProps, {})(withIframeHistory(SupervisorVerificationList, {
  name: SUPERVISOR_VERIFICATION_LIST,
  defaultUrl: Config.reactApp.SUPERVISOR_VERIFICATION_LIST_URL
}));
