import { connect } from 'react-redux';
import PerformOperatorVerification from '../../Components/PerformOperatorVerification';
import withIframeHistory from '../withIframeHistory';
import { mapToIframeLanguage } from '../../translations/i18n';
import { PERFORM_OPERATOR_VERIFICATION } from '../../constants/screens';
import Config from '../../Config';

const mapStateToProps = (state, ownProps) => ({
  currentLanguage: mapToIframeLanguage(state.auth.currentLanguage),
  currentUrl: state.iframeHistory[PERFORM_OPERATOR_VERIFICATION],
  ...ownProps
});

export default connect(mapStateToProps, {})(withIframeHistory(PerformOperatorVerification, {
  name: PERFORM_OPERATOR_VERIFICATION,
  defaultUrl: Config.reactApp.PERFORM_OPERATOR_VERIFICATION_URL
}));
