import { connect } from 'react-redux';
import { mapToIframeLanguage } from '../../translations/i18n';
import SupervisorOperatorRiskTaskBasedChecklistComponent from '../../Components/SupervisorOperatorRiskTaskBasedChecklist';
import { SUPERVISOR_OPERATOR_RISK_TBC } from '../../constants/screens';
import withIframeHistory from '../withIframeHistory';
import Config from '../../Config';

const mapStateToProps = (state, ownProps) => ({
  currentLanguage: mapToIframeLanguage(state.auth.currentLanguage),
  currentUrl: state.iframeHistory[SUPERVISOR_OPERATOR_RISK_TBC],
  ...ownProps,
});

export default connect(
  mapStateToProps,
  {}
)(
  withIframeHistory(SupervisorOperatorRiskTaskBasedChecklistComponent, {
    name: SUPERVISOR_OPERATOR_RISK_TBC,
    defaultUrl: Config.reactApp.SUPERVISOR_OPERATOR_RISK_TB_CHECKLIST_URL,
  })
);
