import { connect } from 'react-redux';
import ManagerControlChecklistApprovals from '../../Components/ManagerControlChecklistApprovals';
import { mapToIframeLanguage } from '../../translations/i18n';

const mapStateToProps = (state, ownProps) => ({
  currentLanguage: mapToIframeLanguage(state.auth.currentLanguage),
  ...ownProps,
});

export default connect(mapStateToProps, {})(ManagerControlChecklistApprovals);
