import { connect } from 'react-redux';
import DailyNotificationList from '../../Components/DailyNotificationList';
import withIframeHistory from '../withIframeHistory';
import { mapToIframeLanguage } from '../../translations/i18n';
import { DAILY_NOTIFICATION_LIST } from '../../constants/screens';
import Config from '../../Config';

const mapStateToProps = (state, ownProps) => ({
  currentLanguage: mapToIframeLanguage(state.auth.currentLanguage),
  currentUrl: state.iframeHistory[DAILY_NOTIFICATION_LIST],
  ...ownProps,
});

export default connect(mapStateToProps, {})(withIframeHistory(DailyNotificationList, {
  name: DAILY_NOTIFICATION_LIST,
  defaultUrl: Config.reactApp.DAILY_NOTIFICATION_LIST_URL
}));
