import { connect } from 'react-redux';
import VerificationScheduleManagement from '../../Components/VerificationScheduleManagement';
import withIframeHistory from '../withIframeHistory';
import { mapToIframeLanguage } from '../../translations/i18n';
import { VERIFICATION_SCHEDULE_MANAGEMENT } from '../../constants/screens';
import Config from '../../Config';

const mapStateToProps = (state, ownProps) => ({
  currentLanguage: mapToIframeLanguage(state.auth.currentLanguage),
  currentUrl: state.iframeHistory[VERIFICATION_SCHEDULE_MANAGEMENT],
  ...ownProps,
});

export default connect(mapStateToProps, {})(withIframeHistory(VerificationScheduleManagement, {
  name: VERIFICATION_SCHEDULE_MANAGEMENT,
  defaultUrl: Config.reactApp.VERIFICATION_SCHEDULE_MANAGEMENT_URL
}));
