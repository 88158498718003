import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { getParsedIrameUrl } from 'componentlibrary/dist/utils/IframeUrl';
import { LoadingIframe } from 'componentlibrary';
import Can from '../Can';
import Config from '../../Config';
import { CREATE_MANAGER_CONTROL_CHECKLIST } from '../../constants/screens';

export default function CreateManagerControlChecklistComponent({
  currentUrl,
  currentLanguage,
  onUnMount,
  onMount,
}) {
  const parsedIframeUrl = useMemo(
    () => getParsedIrameUrl(currentUrl, currentLanguage),
    [currentLanguage]
  );

  useEffect(() => {
    onMount(
      getParsedIrameUrl(
        Config.reactApp.CREATE_MANAGER_CONTROL_CHECKLIST_URL,
        currentLanguage
      )
    );

    return () => {
      onUnMount();
    };
  }, []);

  return (
    <Can I="view" a={CREATE_MANAGER_CONTROL_CHECKLIST}>
      <LoadingIframe src={parsedIframeUrl} />
    </Can>
  );
}

CreateManagerControlChecklistComponent.propTypes = {
  currentLanguage: PropTypes.string.isRequired,
  currentUrl: PropTypes.string.isRequired,
  onMount: PropTypes.func,
  onUnMount: PropTypes.func,
};

CreateManagerControlChecklistComponent.defaultProps = {
  onUnMount: () => {},
  onMount: () => {},
};
