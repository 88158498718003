import { connect } from 'react-redux';
import ReportSubmissionTotals from '../../Components/ReportSubmissionTotals';
import withIframeHistory from '../withIframeHistory';
import { mapToIframeLanguage } from '../../translations/i18n';
import { REPORT_SUBMISSION_TOTALS } from '../../constants/screens';
import Config from '../../Config';

const mapStateToProps = (state, ownProps) => ({
  currentLanguage: mapToIframeLanguage(state.auth.currentLanguage),
  currentUrl: state.iframeHistory[REPORT_SUBMISSION_TOTALS],
  ...ownProps,
});

export default connect(mapStateToProps, {})(withIframeHistory(ReportSubmissionTotals, {
  name: REPORT_SUBMISSION_TOTALS,
  defaultUrl: Config.reactApp.REPORT_SUBMISSION_TOTALS_URL
}));
