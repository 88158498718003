import { connect } from 'react-redux';
import ReportActionPlanStatus from '../../Components/ReportActionPlanStatus';
import withIframeHistory from '../withIframeHistory';
import { mapToIframeLanguage } from '../../translations/i18n';
import { REPORT_ACTION_PLAN_STATUS } from '../../constants/screens';
import Config from '../../Config';

const mapStateToProps = (state, ownProps) => ({
  currentLanguage: mapToIframeLanguage(state.auth.currentLanguage),
  currentUrl: state.iframeHistory[REPORT_ACTION_PLAN_STATUS],
  ...ownProps,
});

export default connect(mapStateToProps, {})(withIframeHistory(ReportActionPlanStatus, {
  name: REPORT_ACTION_PLAN_STATUS,
  defaultUrl: Config.reactApp.REPORT_ACTION_PLAN_STATUS_URL
}));
