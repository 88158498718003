import { connect } from 'react-redux';
import CreateDailyNotification from '../../Components/CreateDailyNotification';
import withIframeHistory from '../withIframeHistory';
import { mapToIframeLanguage } from '../../translations/i18n';
import { CREATE_DAILY_NOTIFICATION } from '../../constants/screens';
import Config from '../../Config';

const mapStateToProps = (state, ownProps) => ({
  currentLanguage: mapToIframeLanguage(state.auth.currentLanguage),
  currentUrl: state.iframeHistory[CREATE_DAILY_NOTIFICATION],
  ...ownProps,
});

export default connect(mapStateToProps, {})(withIframeHistory(CreateDailyNotification, {
  name: CREATE_DAILY_NOTIFICATION,
  defaultUrl: Config.reactApp.CREATE_DAILY_NOTIFICATION_URL
}));
