import { connect } from 'react-redux';
import ManagerControlChecklist from '../../Components/ManagerControlChecklist';
import withIframeHistory from '../withIframeHistory';
import { mapToIframeLanguage } from '../../translations/i18n';
import { MANAGER_CONTROL_CHECKLIST } from '../../constants/screens';
import Config from '../../Config';

const mapStateToProps = (state, ownProps) => ({
  currentLanguage: mapToIframeLanguage(state.auth.currentLanguage),
  currentUrl: state.iframeHistory[MANAGER_CONTROL_CHECKLIST],
  ...ownProps
});

export default connect(mapStateToProps, {})(withIframeHistory(ManagerControlChecklist, {
  name: MANAGER_CONTROL_CHECKLIST,
  defaultUrl: Config.reactApp.MANAGER_CONTROL_CHECKLIST_URL
}));
