import { connect } from 'react-redux';
import ReportStandardReport from '../../Components/ReportStandardReport';
import withIframeHistory from '../withIframeHistory';
import { mapToIframeLanguage } from '../../translations/i18n';
import { REPORT_STANDARD_REPORT } from '../../constants/screens';
import Config from '../../Config';

const mapStateToProps = (state, ownProps) => ({
  currentLanguage: mapToIframeLanguage(state.auth.currentLanguage),
  currentUrl: state.iframeHistory[REPORT_STANDARD_REPORT],
  ...ownProps,
});

export default connect(mapStateToProps, {})(withIframeHistory(ReportStandardReport, {
  name: REPORT_STANDARD_REPORT,
  defaultUrl: Config.reactApp.REPORT_STANDARD_REPORT_URL
}));
