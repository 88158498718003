// unused or removed
export const CREATE_MANAGER_CONTROL_CHECKLIST = 'CreateManagerControlChecklist';
export const MANAGER_CONTROL_CHECKLIST_APPROVALS = 'ManagerControlChecklistApprovals';
export const MANAGER_CONTROL_CHECKLIST_APPROVALS_SETTING = 'ManagerControlChecklistApprovalsSetting';
export const REPORT_AP_EFFECT_REPORT = 'ReportApEffectReport';
export const REPORT_QUESTION_NON_COMPLIANCE_REPORT = 'ReportQuestionNonComplianceReport';
// Homepage
export const HOME_PAGE = 'HomePage';

// Manager Menu
export const PERFORM_MANAGER_VERIFICATION = 'PerformManagerVerification';
export const MANAGER_CONTROL_CHECKLIST = 'ManagerControlChecklist';
export const MANAGER_VERIFICATION_LIST = 'ManagerVerificationList';
// Supervisor Menu
export const PERFORM_SUPERVISOR_VERIFICATION = 'PerformSupervisorVerification';
export const PERFORM_SUPERVISOR_TASK_BASED_VERIFICATION = 'PerformSupervisorTaskBasedVerification';
export const SUPERVISOR_VERIFICATION_LIST = 'SupervisorVerificationList';
export const SUPERVISOR_OPERATOR_RISK_TBC = 'SupervisorOperatorRiskTaskBasedChecklist';
export const CREATE_SUPERVISOR_OPERATOR_RISK_TBC = 'CreateSupervisorOperatorTaskBasedChecklist';

// Operator Menu
export const PERFORM_OPERATOR_VERIFICATION = 'PerformOperatorVerification';
export const PERFORM_OPERATOR_TASK_BASED_VERIFICATION = 'PerformOperatorTaskBasedVerification';
// Daily Notifications Menu
export const CREATE_DAILY_NOTIFICATION = 'CreateDailyNotification';
export const DAILY_NOTIFICATION_LIST = 'DailyNotificationList';
// Verification Schedule Menu
export const VERIFICATION_SCHEDULE = 'VerificationSchedule';
export const VERIFICATION_SCHEDULE_MANAGEMENT = 'VerificationScheduleManagement';
// Reports Menu
export const REPORT_SUBMISSION_TOTALS = 'ReportSubmissionTotals';
export const REPORT_STANDARD_REPORT = 'ReportStandardReport';
export const REPORT_VERIFICATION_SCHEDULER_REPORTS = 'ReportVerificationSchedulerReports';
export const REPORT_ACTION_PLAN_REPORT = 'ReportActionPlanReport';
export const REPORT_VERIFICATION_PARTICIPATION_SUMMARY = 'ReportVerificationParticipationSummary';
export const REPORT_ACTION_PLAN_STATUS = 'ReportActionPlanStatus';
