import React from 'react';
import PropTypes from 'prop-types';
import { LoadingIframe } from 'componentlibrary';
import Can from '../Can';
import Config from '../../Config';

export default function ManagerControlChecklistApprovalsComponent({ currentLanguage }) {
  return (
    <Can I="view" a="ManagerControlChecklistApprovals">
      <LoadingIframe src={`${Config.reactApp.MANAGER_CONTROL_CHECKLIST_APPROVALS_URL}?language_select=${currentLanguage}`} />
    </Can>
  );
}

ManagerControlChecklistApprovalsComponent.propTypes = {
  currentLanguage: PropTypes.string.isRequired
};
