import { connect } from 'react-redux';
import ManagerControlChecklistApprovalsSetting from '../../Components/ManagerControlChecklistApprovalsSetting';
import { mapToIframeLanguage } from '../../translations/i18n';

const mapStateToProps = (state, ownProps) => ({
  currentLanguage: mapToIframeLanguage(state.auth.currentLanguage),
  ...ownProps,
});

export default connect(mapStateToProps, {})(ManagerControlChecklistApprovalsSetting);
