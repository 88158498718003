import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { LoadingIframe, getParsedIrameUrl } from 'componentlibrary';
import Can from '../Can';
import Config from '../../Config';
import { MANAGER_VERIFICATION_LIST } from '../../constants/screens';

export default function ManagerVerificationListComponent({
  currentUrl, currentLanguage, onUnMount, onMount
}) {
  const parsedIframeUrl = useMemo(() => getParsedIrameUrl(currentUrl, currentLanguage), [currentLanguage]);

  useEffect(() => {
    onMount(getParsedIrameUrl(Config.reactApp.MANAGER_VERIFICATION_LIST_URL, currentLanguage));

    return () => {
      onUnMount();
    };
  }, []);

  return (
    <Can I="view" a={MANAGER_VERIFICATION_LIST}>
      <LoadingIframe src={parsedIframeUrl} />
    </Can>
  );
}

ManagerVerificationListComponent.propTypes = {
  currentLanguage: PropTypes.string.isRequired,
  currentUrl: PropTypes.string.isRequired,
  onMount: PropTypes.func,
  onUnMount: PropTypes.func
};

ManagerVerificationListComponent.defaultProps = {
  onUnMount: () => {},
  onMount: () => {}
};
